<template>
  <div id="merchant-subList-actionDialog">
    <el-dialog
      :title="userId ? '编辑子账号' : '新增子账号'"
      :visible.sync="show"
      :close-on-press-escape="false"
      @closed="reset"
    >
      <el-form
        :model="addForm"
        :rules="addFormRules"
        ref="addForm"
        label-width="140px"
      >
        <el-form-item label="商户联系人" prop="contactName">
          <el-input
            v-model="addForm.contactName"
            placeholder="请输入商户联系人"
          ></el-input>
        </el-form-item>
        <el-form-item label="联系电话" prop="contactPhone">
          <el-input
            v-model="addForm.contactPhone"
            placeholder="请输入联系电话"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录名" prop="username">
          <el-input
            :disabled="!!userId"
            v-model="addForm.username"
            placeholder="请输入登录名"
          ></el-input>
        </el-form-item>
        <el-form-item label="登录密码" :prop="!userId ? 'password' : ''">
          <el-input
            type="password"
            v-model="addForm.password"
            placeholder="请输入登录密码"
          ></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button size="medium" @click="show = false">取 消</el-button>
        <el-button
          type="primary"
          size="medium"
          @click="confirmBtn"
          :loading="loading"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>
<script>
export default {
  props: ["getList"],
  data() {
    return {
      show: false,
      loading: false,
      userId: "",
      addForm: {
        contactName: "",
        contactPhone: "",
        username: "",
        password: "",
      },
      addFormRules: {
        contactName: [
          { required: true, message: "请输入商户联系人", trigger: "blur" },
        ],
        contactPhone: [
          { required: true, message: "请输入联系电话", trigger: "blur" },
        ],
        username: [
          { required: true, message: "请输入登录名", trigger: "blur" },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
        ],
      },
    };
  },
  methods: {
    reset() {
      this.userId = "";
      this.$set(this, "addForm", {
        contactName: "",
        contactPhone: "",
        username: "",
        password: "",
      });
      this.$refs["addForm"].resetFields();
    },
    confirmBtn() {
      this.$refs["addForm"].validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            let url = "/parking/shop/sub/add";
            let params = {
              ...this.addForm,
              password: this.addForm.password ? this.addForm.password : null,
            };
            if (this.userId) {
              url = "/parking/shop/sub/edit";
              params.userId = this.userId;
              params.username = null;
            } else {
              params.parentUserId = this.$route.params.id;
            }
            let res = await this.$http.post(url, params);
            if (res.code === 0) {
              this.getList();
              this.$message.success("操作成功");
              this.show = false;
            } else {
              this.$message.error(res.msg);
            }
          } catch (err) {
            this.$message.error(err);
          } finally {
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<style lang="scss">
#merchant-subList-actionDialog {
  .el-dialog__wrapper {
    .el-dialog {
      width: 400px;
      .el-dialog__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px;
        border: 1px solid rgba(20, 34, 57, 0.13);
        .el-dialog__title {
          font-size: 14px;
          color: #142239;
          position: relative;
          z-index: 2;
          font-weight: bold;
          &::after {
            content: "";
            display: inline-block;
            width: 100%;
            height: 3px;
            background-color: $main-color;
            position: absolute;
            left: 0;
            bottom: 4px;
            z-index: -1;
          }
        }
        .el-dialog__headerbtn {
          position: initial;
          i {
            color: black;
          }
        }
      }
      .el-dialog__body {
        padding: 15px;
        .el-select {
          width: 100%;
        }
      }
      .el-dialog__footer {
        text-align: center;
        .dialog-footer {
          .el-button--default {
            border-color: $main-color;
            color: $main-color;
          }
        }
      }
    }
  }
}
</style>
